//OUR STORY
export const OUR_STORY_1 = `Andrew & Jocelyn first meet at UCLA after joining the same business fraternity. Andrew, good friend that he is, helps Jocelyn with her resume to land her first job in accounting. Years later, the two reconnect at a friend's going-away party.`
export const OUR_STORY_2 = `After 6 months of dating, Andrew moves to Singapore for Netflix. Jocelyn invites herself to move there with him. They fall in love and travel all over Asia together. (And Jocelyn leaves accounting to pursue interior design!)`
export const OUR_STORY_3 = `The pandemic years turn Jocelyn into a gamer (Andrew has always been one). From the nights of Among Us to the even later nights of Valorant, the two spend countless hours gaming together with friends.`
export const OUR_STORY_4 = `Along the oceanside views to the north of Porto, Portugal and with a belly full of egg tarts, Andrew pops the question in the wet grass, staining his nice suit. They're engaged!`

//TRAVEL & STAY
export const EBELL_ADDRESS_TITLE = `The Ebell Club Long Beach`
export const EBELL_STREET_ADDRESS = `290 Cerritos Ave`
export const EBELL_CITY_ZIP = `Long Beach, CA 90802`
export const EBELL_GOOGLE_URL = `https://maps.app.goo.gl/iQxpHnGvRpvVTQbF8`

export const PARKING_ADDRESS_TITLE = `Seventh-day Adventist Church`
export const PARKING_STREET_ADDRESS = `1001 E 3rd St`
export const PARKING_CITY_ZIP = `Long Beach, CA 90802`
export const PARKING_GOOGLE_URL = `https://maps.app.goo.gl/nySExtwmEcvoYPkPA`

export const TRAFFIC_INFO = `Please account for typical Friday traffic while traveling to the venue. Estimated driving time from LA is 1.5 to 2 hours during rush hour.`
export const PARKING_INFO = `Parking is located at a nearby church, one block from the venue. There will be a security guard to help guide guests.`
export const STAY_INFO = `We expect most guests will be coming from the Los Angeles Area. If you are from out of town and prefer accomodations near the venue, we recommend these hotels. Please reach out to us if you need lodging recommendations in LA.`
export const WESTIN_ADDRESS_TITLE = `The Westin`
export const WESTIN_STREET_ADDRESS = `333 E Ocean Blvd`
export const WESTIN_CITY_ZIP = `Long Beach, CA 90802`
export const WESTIN_GOOGLE_URL = `https://maps.app.goo.gl/dFPJJMbuwWSfsXo87`

export const HYATT_ADDRESS_TITLE = `Hyatt Regency`
export const HYATT_STREET_ADDRESS = `200 S Pine Ave`
export const HYATT_CITY_ZIP = `Long Beach, CA 90802`
export const HYATT_GOOGLE_URL = `https://maps.app.goo.gl/dDKMfYVd6Qcg6ArA7`

//REGISTRY
export const REGISTRY_INFO = `Your well wishes and company are all we need. For friends and family that would like to honor us with a gift, please contribute to our Honeymoon fund!`
export const VENMO_INFO = `Contributions can be made through our Venmo: @Jocelyn-Lee`
export const ZELLE_INFO = `Zelle: jocelynkrislee@gmail.com`
export const VENMO_ID = `@Jocelyn-Lee`
export const ZELLE_ID = `jocelynkrislee@gmail.com`

//FAQ
export const FAQ_ITEMS = [
  {
    question: 'What is the venue like?',
    answer:
      'The venue is completely indoors, but with a large skylight to bring in ample natural light. One reason we chose the venue is so that we should be set rain or shine!',
  },
  {
    question: 'What time is the ceremony?',
    answer: 'The ceremony will start promptly at 5:30PM. Please plan to arrive around 5:00PM.',
  },
  {
    question: 'What should I wear?',
    answer: 'The dress code is',
    link: 'https://www.brides.com/black-tie-optional-wedding-attire-4800698',
  },
  {
    question: 'Where should I stay?',
    answer:
      'We expect most guests to be from the LA area. If you are from out of town, staying in LA and driving is reasonable and there are also many hotels in the nearby Long Beach area. Please refer to the TRAVEL & STAY section of our website for more information.',
  },
  {
    question: 'Where should I park?',
    answer:
      'Plenty of parking will be available for guests at the Seventh-day Adventist Church, just a 5-minute walk from the venue. There will be a security guard to help guide guests.',
  },
  {
    question: 'Can I bring my children?',
    answer:
      'We love your little ones! But we are only accommodating guests specifically listed on the invitation. Thank you for understanding!',
  },
]

import React from 'react'
import Hyatt from '../../assets/img/stay/hyatt.webp'
import Westin from '../../assets/img/stay/westin.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'

import {
  STAY_INFO,
  WESTIN_ADDRESS_TITLE,
  WESTIN_STREET_ADDRESS,
  WESTIN_CITY_ZIP,
  WESTIN_GOOGLE_URL,
  HYATT_ADDRESS_TITLE,
  HYATT_STREET_ADDRESS,
  HYATT_CITY_ZIP,
  HYATT_GOOGLE_URL,
} from '../../common/Strings'

const StayInfo = () => {
  const textClass = 'text-2xl lg:text-3xl font-light'
  const linkClass = 'text-blue-400 underline text-2xl lg:text-3xl hover:text-blue-300 font-light'

  return (
    <div
      id='stay-info'
      className='scroll-mt-0 text-ja-gray flex flex-col items-center justify-center w-full bg-ja-white'
    >
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl flex flex-col py-10 lg:py-16 items-center justify-center '>
        {/* Main Headline */}
        <div className='flex flex-row w-full items-center justify-center'>
          <div className='flex flex-row items-center justify-center'>
            <h1 className={HBSHeader}>S</h1>
            <h1 className={MundayHeader}>TAY</h1>
          </div>
        </div>
        <div className='px-10 flex flex-col mt-6'>
          <p className={textClass}>{STAY_INFO}</p>
          <div className='flex w-full mt-8 flex-col lg:flex-row lg:justify-between lg:px-20'>
            <div className='flex flex-col lg:w-2/5'>
              <div className='border-2 border-gray-300 p-2 mt-10 w-full'>
                <div
                  style={{
                    backgroundImage: `url(${Westin})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  className='w-full h-[300px]'
                />
              </div>
              <div className='flex flex-col items-center mt-6'>
                <p className={`${textClass} text-3xl mb-4`}>{WESTIN_ADDRESS_TITLE}</p>
                <p className={textClass}>{WESTIN_STREET_ADDRESS}</p>
                <p className={textClass}>{WESTIN_CITY_ZIP}</p>
                <a href={WESTIN_GOOGLE_URL} target='_blank' rel='noopener noreferrer' className={linkClass}>
                  View on Google Maps
                </a>
              </div>
            </div>
            <div className='flex flex-col lg:w-2/5'>
              <div className='border-2 border-gray-300 p-2 mt-10 w-full'>
                <div
                  style={{
                    backgroundImage: `url(${Hyatt})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                  className='w-full h-[300px]'
                />
              </div>
              <div className='flex flex-col items-center mt-6'>
                <p className={`${textClass} text-3xl mb-4`}>{HYATT_ADDRESS_TITLE}</p>
                <p className={textClass}>{HYATT_STREET_ADDRESS}</p>
                <p className={textClass}>{HYATT_CITY_ZIP}</p>
                <a href={HYATT_GOOGLE_URL} target='_blank' rel='noopener noreferrer' className={linkClass}>
                  View on Google Maps
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StayInfo

import React from 'react'
import { ReactComponent as RSVPBackground } from '../assets/backgrounds/RSVPBackground.svg'
import HeaderBackground from '../assets/backgrounds/HeaderBackground.svg'

import { IoMdCar, IoIosHome, IoMdClock, IoMdGift, IoMdHelp, IoMdHeart } from 'react-icons/io'

import { Link } from 'react-router-dom'

const openUrl = () => {
  window.open('https://www.zola.com/wedding/andrewlyn/rsvp', '_blank')
}

function GameHeader() {
  return (
    <div className='absolute flex flex-row w-[1100px] h-[60px] text-2xl justify-center bg-transparent'>
      {/* <div className="w-[600px] h-0 border-t-[100px] border-solid border-transparent border-t-black"></div> */}
      <img src={HeaderBackground} alt='' className='absolute inline-block' />
      <div className='relative flex items-center justify-center space-x-4 z-50'>
        <HeaderButton icon={IoIosHome} path='/gamer' tooltip='home' />
        <HeaderButton icon={IoMdHeart} path='/gamer/our-story' tooltip='our story' />
        <HeaderButton icon={IoMdClock} path='/gamer/schedule' tooltip='schedule' />
      </div>
      <div onClick={openUrl} className='flex items-center justify-center group'>
        <RSVPBackground className='absolute inline-block text-[#f0133c] z-0 group-hover:text-white cursor-pointer' />
        <p className='relatve flex items-center justify-center w-[200px] z-50 mx-24 text-white group-hover:text-black font-bold text-5xl cursor-pointer'>
          RSVP
        </p>
      </div>
      <div className='relative flex items-center justify-center space-x-4 z-50'>
        <HeaderButton icon={IoMdCar} path='/gamer/travel' tooltip='travel & stay' />
        <HeaderButton icon={IoMdGift} path='/gamer/registry' tooltip='registry' />
        <HeaderButton icon={IoMdHelp} path='/gamer/faq' tooltip='faq' />
      </div>
    </div>
  )
}

function HeaderButton({ icon: Icon, path, tooltip }) {
  return (
    <Link to={path}>
      <div className='flex w-20 h-20 items-center justify-center'>
        {/* <img src={icon} alt='' className='w-9 h-9' /> */}
        <Tooltip text={tooltip}>
          <Icon className='text-white text-4xl hover:text-game-teal' />
        </Tooltip>
      </div>
    </Link>
  )
}

const Tooltip = ({ children, text }) => {
  return (
    <div className='relative flex flex-col items-center group'>
      {children}
      <div className='absolute top-full mt-2 hidden group-hover:flex justify-center'>
        <div className='px-4 py-2 text-3xl text-white bg-slate-700 bg-opacity-70 w-48 item-center justify-center text-center'>
          {text}
        </div>
      </div>
    </div>
  )
}

export default GameHeader

import React from 'react'
import { Outlet, useLocation, Link } from 'react-router-dom'
import GameHeader from './components/GameHeader'

import OurStoryBackground from './assets/backgrounds/our-story-background.webp'
import Logo from './assets/photos/aj-game-logo.png'

//Mobile standard font - 2xl
//Large standard font - 3xl

function Game() {
  const location = useLocation()
  return (
    <div
      className={`flex flex-col items-center text-2xl font-avilock bg-gradient-to-br from-slate-900 to-slate-400`}
      style={{
        backgroundImage: `url(${OurStoryBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      {location?.pathname === '/gamer' ? null : <GameHeader />}
      <Link to='/'>
        <img src={Logo} alt='' className='absolute left-10 top-10 w-16 3xl:w-20' />
      </Link>
      <Outlet />
    </div>
  )
}

export default Game

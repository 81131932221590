import React, { useState } from 'react'
import OurStoryBackground from '../assets/backgrounds/our-story-background.webp'
import Photo1 from '../assets/photos/our-story-1.webp'
import Photo4 from '../assets/photos/our-story-2.webp'
import Photo3 from '../assets/photos/our-story-3.webp'
import Photo2 from '../assets/photos/our-story-4.webp'
import Divider from '../assets/icons/our-story-divider.webp'

import { OUR_STORY_1, OUR_STORY_2, OUR_STORY_3, OUR_STORY_4 } from '../../common/Strings'

const GalleryTextData = [OUR_STORY_1, OUR_STORY_2, OUR_STORY_3, OUR_STORY_4]
const MainPhotoData = [Photo1, Photo2, Photo3, Photo4]

function GameStory() {
  const [selectedImage, setSelectedImage] = useState(0)

  return (
    <div
      className='flex flex-row justify-center items-center text-2xl overflow-hidden pt-[140px] 4xl:pt-[180px]'
      style={{
        backgroundImage: `url(${OurStoryBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='w-[1200px] 3xl:w-[1600px] 4xl:w-[1800px] h-full flex flex-row px-10 3xl:px-20'>
        <div className='flex flex-row w-full h-[500px] 3xl:h-[620px] 4xl:h-[720px] items-center justify-center '>
          <MainPhoto selectedImage={selectedImage} />
          <Gallery selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
        </div>
      </div>
    </div>
  )
}

function MainPhoto({ selectedImage }) {
  return (
    <div className='flex flex-col w-1/2 items-center justify-center text-2xl h-full '>
      <div className='flex items-center justify-center'>
        <div className='p-2 border border-game-border-gray'>
          <ImageSquareCrop
            src={MainPhotoData[selectedImage]}
            alt='Photo1'
            style={
              'w-[424px] h-[424px] 3xl:w-[560px] 3xl:h-[560px]  4xl:w-[620px] 4xl:h-[620px]'
            }
          />
        </div>
      </div>
    </div>
  )
}

function Gallery({ selectedImage, setSelectedImage }) {
  return (
    <div className='flex flex-row w-1/2 items-center justify-center text-2xl h-full'>
      <div className='relative flex w-full h-full items-center justify-start'>
        <img
          src={Divider}
          alt=''
          className='absolute left-0 transform -translate-x-[16px] 3xl:-translate-x-[40px] h-[475px] 3xl:h-[614px] 4xl:h-[690px] z-0'
        />
        <div className='flex flex-col w-[800px] h-[800px] items-center justify-center pl-14 z-10'>
          <div className='flex flex-col h-1/2 w-full justify-end pb-2 px-4 4xl:px-10'>
            <p className='text-8xl 3xl:text-9xl 4xl:text-10xl text-game-header-yellow mb-10 3xl:mb-16'>Our Story</p>
            <div className='flex flex-row space-x-2'>
              <DateHeader date='2012' active={selectedImage === 0} onClick={() => setSelectedImage(0)} />
              <DateHeader date='2017' active={selectedImage === 1} onClick={() => setSelectedImage(1)} />
              <DateHeader date='2020' active={selectedImage === 2} onClick={() => setSelectedImage(2)} />
              <DateHeader date='2023' active={selectedImage === 3} onClick={() => setSelectedImage(3)} />
            </div>
          </div>
          <div className='h-1/2 w-full pt-1 px-4 4xl:px-10'>
            <div className='flex flex-row space-x-2'>
              <ImageSquareCrop
                src={Photo1}
                alt='Photo1'
                style='w-[110px] h-[110px] 3xl:w-36 3xl:w-36 3xl:h-36 4xl:w-40 3xl:h-40'
                onClick={() => setSelectedImage(0)}
              />
              <ImageSquareCrop
                src={Photo2}
                alt='Photo1'
                style='w-[110px] h-[110px] 3xl:w-36 3xl:w-36 3xl:h-36 4xl:w-40 3xl:h-40'
                onClick={() => setSelectedImage(1)}
              />
              <ImageSquareCrop
                src={Photo3}
                alt='Photo1'
                style='w-[110px] h-[110px] 3xl:w-36 3xl:w-36 3xl:h-36 4xl:w-40 3xl:h-40'
                onClick={() => setSelectedImage(2)}
              />
              <ImageSquareCrop
                src={Photo4}
                alt='Photo1'
                style='w-[110px] h-[110px] 3xl:w-36 3xl:w-36 3xl:h-36 4xl:w-40 3xl:h-40'
                onClick={() => setSelectedImage(3)}
              />
            </div>
            <GalleryText selectedImage={selectedImage} />
          </div>
        </div>
      </div>
    </div>
  )
}

function DateHeader({ date, active, onClick }) {
  return (
    <button
      onClick={onClick}
      className={`flex flex-row items-center justify-center w-[110px] 3xl:w-36 4xl:w-40 h-16 bg-opacity-70 ${
        active ? 'bg-gray-400' : 'bg-gray-600'
      }`}
    >
      <p className='text-white text-4xl '>{date}</p>
    </button>
  )
}

function GalleryText({ selectedImage }) {
  return (
    <div className='flex items-center w-full h-32 3xl:h-40'>
      <p className='text-white text-base 3xl:text-xl 4xl:text-2xl font-sans'>{GalleryTextData[selectedImage]}</p>
    </div>
  )
}

const ImageSquareCrop = ({ src, alt, style, onClick }) => {
  return (
    <button onClick={onClick} className={`flex justify-center items-center overflow-hidden ${style}`}>
      <img src={src} alt={alt} className='object-cover h-full w-full' />
    </button>
  )
}

export default GameStory

import React from 'react'
import FAQBackground from '../assets/backgrounds/faq-background.webp'
import { FAQ_ITEMS } from '../../common/Strings'
import TopBorder from '../assets/photos/faq-top-border.webp'

function FAQ() {
  return (
    <div
      className='flex flex-col items-center text-2xl overflow-hidden pt-[120px] text-white'
      style={{
        backgroundImage: `url(${FAQBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='w-[1200px] 3xl:w-[1600px] 4xl:w-[1800px] h-full flex flex-col px-20 3xl:px-48 4xl:px-64 items-center'>
        <p className='text-game-header-yellow text-8xl 3xl:text-10xl mb-4'>FAQ</p>
        <img src={TopBorder} alt='' className='h-auto w-2/3' />
        <div className='w-full flex flex-col mt-4 3xl:mt-6 4xl:mt-10 space-y-1'>
          {FAQ_ITEMS.map((item, index) => {
            const { question, answer, link } = item
            return (
              <FAQRow
                key={index}
                question={question}
                answer={answer}
                color={index % 2 === 0 ? 'red' : 'green'}
                link={link}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

function FAQRow({ question, answer, color = 'green', link }) {
  return (
    <div className='flex flex-row items-center h-20 4xl:h-24 w-full font-sans'>
      <div
        className={`flex h-full ${
          color === 'green' ? 'bg-game-faq-green' : 'bg-game-faq-red'
        } bg-opacity-95 px-6 w-1/3 items-center text-base 3xl:text-xl 4xl:text-2xl uppercase font-bold`}
      >
        {question}
      </div>
      <div
        className={`flex flex-row h-full ${
          color === 'green' ? 'bg-game-faq-green' : 'bg-game-faq-red'
        } bg-opacity-80 px-6 w-2/3 items-center text-base 3xl:text-lg 4xl:text-xl`}
      >
        <p>{answer}</p>
        {link && (
          <a href={link} target='_blank' className='text-blue-400 hover:text-blue-300'>
            &nbsp;black-tie optional (link).
          </a>
        )}
      </div>
    </div>
  )
}

export default FAQ

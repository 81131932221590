import React from 'react'
import ScheduleBackground from '../assets/backgrounds/schedule-background.webp'
import Photo from '../assets/photos/schedule-photo.webp'
import { EBELL_ADDRESS_TITLE, EBELL_STREET_ADDRESS, EBELL_CITY_ZIP } from '../../common/Strings'

function Schedule() {
  return (
    <div
      className='flex flex-row items-center justify-center text-2xl pt-[120px]'
      style={{
        backgroundImage: `url(${ScheduleBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='w-[1200px] 3xl:w-[1600px] 4xl:w-[1800px] h-full flex flex-col px-20'>
        <p className='flex items-center justify-center text-game-header-yellow text-8xl 3xl:text-9xl 4xl:text-10xl w-full'>SCHEDULE</p>
        <div className='flex flex-row mt-8'>
          <div className='flex flex-col items-end space-y-2 3xl:space-y-2 4xl:space-y-4 w-1/3 h-full pt-6'>
            <ScheduleCard title='Guest Arrival' time='5:00 PM' />
            <ScheduleCard title='Wedding Ceremony' time='5:30 PM' />
            <ScheduleCard title='Cocktail Hour' time='6:00 PM' />
            <ScheduleCard title='Dinner & Reception' time='7:00 PM' />
          </div>
          <div className='flex flex-col w-1/3 text-white text-3xl 3xl:text-4xl items-center mx-14 3xl:mx-20 4xl:mx-28 pt-8 3xl:pt-6'>
            <p className='mt-6 3xl:mt-8 4xl:mt-10 text-5xl 3xl:text-6xl 4xl:text-7xl'>The Ebell Club</p>
            <p className='mt-1 3xl:mt-2 4xl:mt-4 text-5xl 3xl:text-6xl 4xl:text-7xl'>Long Beach</p>
            <p className='mt-4 3xl:mt-6 4xl:mt-8 font-sans text-2xl 3xl:text-3xl 4xl:text-4xl'>
              Friday, October 25 2024
            </p>
            <p className='font-sans text-2xl 3xl:text-3xl 4xl:text-4xl'>5:00 PM - 11:00 PM</p>
            <p className='mt-4 3xl:mt-6 4xl:mt-8 font-sans text-2xl 3xl:text-3xl 4xl:text-4xl'>{EBELL_STREET_ADDRESS}</p>
            <p className='font-sans text-2xl 3xl:text-3xl 4xl:text-4xl'>{EBELL_CITY_ZIP}</p>
          </div>
          <div className='flex flex-col items-start justify-start w-1/3 '>
            <img src={Photo} alt='' className='w-[310px] 3xl:w-[390px] 4xl:w-[460px]' />
          </div>
        </div>
      </div>
    </div>
  )
}

function ScheduleCard({ title, time }) {
  return (
    <div className='flex flex-row w-[300px] 3xl:w-80 4xl:w-96 h-[90px] 3xl:h-28 4xl:h-32 bg-gray-300 bg-opacity-20 text-white'>
      <div className='flex w-1/6 h-full bg-game-brown' />
      <div className='flex w-full h-full items-center px-4 3xl:px-6 4xl:px-8'>
        <div className='flex flex-col space-y-1'>
          <p className='text-3xl 3xl:text-3xl 4xl:text-4xl uppercase'>{title}</p>
          <p className='text-2xl 3xl:text-2xl 4xl:text-3xl font-sans'>{time}</p>
        </div>
      </div>
    </div>
  )
}
export default Schedule

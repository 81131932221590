import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { FiChevronUp } from 'react-icons/fi'

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false)

  let timeoutId

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShowButton(true)
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setShowButton(false)
      }, 3000)
    } else if (window.scrollY < 500) {
      setShowButton(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Cleanup function to remove event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={showButton ? { opacity: 1 } : { opacity: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        className='fixed bottom-6 right-6 lg:bottom-10 lg:right-10 rounded-full p-2 cursor-pointer bg-ja-gray bg-opacity-90'
        onClick={scrollToTop}
      >
        <FiChevronUp className='text-ja-white text-5xl' />
      </motion.div>
    </>
  )
}

export default BackToTop

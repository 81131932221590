export default function ErrorPage() {
  return (
    <main>
      <div className='flex items-center justify-center p-4 min-h-[calc(100vh-64px)]'>
        <div className='flex flex-col items-center w-full max-w-lg'>
          <div className='flex flex-col items-center pb-6 mb-6 border-b border-gray-300'>
            <h1 className='text-9xl font-bold mb-10'>404</h1>
            <h4 className='text-2xl mb-5'>Oops, sorry we can't find that page</h4>
            <span className='text-sm'>Either something went wrong or the page doesn't exist anymore</span>
          </div>
          <a href='/' className="border border-ja-gray rounded-md px-4 py-2">
            Home page
          </a>
        </div>
      </div>
    </main>
  )
}

import React from 'react'
// import Image2 from '../../assets/img/Image2.jpg'
// import Noise from '../../assets/img/noise.svg'
// import RSVPButton from './RSVPButton'
import Image1 from '../../assets/img/our-story/our-story-1.webp'
import Image2 from '../../assets/img/our-story/our-story-2.webp'
import Image3 from '../../assets/img/our-story/our-story-3.webp'
import Image4 from '../../assets/img/our-story/our-story-4.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'

import { OUR_STORY_1, OUR_STORY_2, OUR_STORY_3, OUR_STORY_4 } from '../../common/Strings'

const OurStory = () => {
  return (
    <div id='our-story' className='scroll-mt-0 text-ja-gray flex flex-row items-center justify-center w-full'>
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl  flex flex-col justify-center items-center py-10 lg:py-16'>
        {/* Main Headline */}
        <div className='flex flex-row md:flex-col items-center justify-center w-full text-left mb-10 md:mb-0'>
          <div className='flex flex-col md:flex-row w-full items-start md:items-center justify-center px-8'>
            <div className='flex flex-row items-center justify-start md:justify-end px-10'>
              <h1 className={`${MundayHeader}`}>OUR</h1>
            </div>
            <div className='flex flex-row items-center justify-end md:justify-start ml-auto md:ml-0 px-10 md:px-0'>
              <h1 className={`${HBSHeader}`}>S</h1>
              <h1 className={`${MundayHeader}`}>TORY</h1>
            </div>
          </div>
        </div>
        <div className='flex w-full flex-col lg:flex-row items-start justify-center'>
          <StoryItem year='2015' image={Image1} description={OUR_STORY_1} />
          <StoryItem year='2017' image={Image2} description={OUR_STORY_2} />
          <StoryItem year='2020' image={Image3} description={OUR_STORY_3} />
          <StoryItem year='2023' image={Image4} description={OUR_STORY_4} />
        </div>
      </div>
    </div>
  )
}

const StoryItem = ({ year, image, description }) => {
  return (
    <div className='flex flex-col items-center justify-center w-full mb-6'>
      <p className='text-4xl py-4 px-6'>{year}</p>
      <div className='border-2 border-gray-300 p-2'>
        <div
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className='w-80 h-80 md:w-64 md:h-64 lg:w-72 lg:h-72 3xl:w-96 3xl:h-96'
        />
      </div>
      <p className='text-2xl text-center max-w-[310px] lg:w-[310px] 3xl:w-[384px] 3xl:max-w-[384px] py-6'>{description}</p>
    </div>
  )
}

export default OurStory

import React from 'react'
import HeroImage from '../../assets/img/carousel/HeroBanner.jpg'
import Image1 from '../../assets/img/carousel/carousel-1.webp'
import Image2 from '../../assets/img/carousel/carousel-2.webp'
import Image3 from '../../assets/img/carousel/carousel-3.webp'
import Image4 from '../../assets/img/carousel/carousel-4.webp'
import Image5 from '../../assets/img/carousel/carousel-5.webp'
import Image6 from '../../assets/img/carousel/carousel-6.webp'
// import HeroImage from '../../assets/img/Panel 1 Image.png'

const ImageCarousel = () => {
  return (
    <div
      className='bg-stone-800 text-gray-800 w-full h-[450px] lg:h-[850px] relative'
      // style={{
      //   backgroundImage: `url(${HeroImage})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
    >
      {/* <img src={HeroImage} alt="HeroImage" className="absolute" /> */}
      <div
        className='bg-stone-800 text-gray-800 w-full h-[450px] grayscale brightness-50 lg:h-[850px] absolute z-0'
        style={{
          backgroundImage: `url(${HeroImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <div className='flex flex-row w-full items-start justify-center z-10 absolute py-10 lg:py-16'>
        <div className='container flex items-center justify-center'>
          <div className='hidden lg:flex flex-col items-end justify-center mr-20 3xl:mr-32'>
            <ImagePoster src={Image2} alt='image2' style='h-[360px] 3xl:h-[380px] hidden lg:flex' />
            <ImagePoster src={Image1} alt='image1' style='h-[240px] 3xl:h-[260px] hidden lg:flex' margin='mt-10 mr-52' />
          </div>
          <div className='hidden lg:flex flex-col items-center justify-center'>
            <ImagePoster src={Image3} alt='image3' style='h-60 3xl:h-64  hidden lg:flex' margin='mr-16' />
            <ImagePoster src={Image4} alt='image4' style='h-72 3xl:h-80  hidden lg:flex' margin='ml-40 mt-8' />
          </div>
          <div className='hidden lg:flex flex-shrink flex-col items-start justify-center ml-20 3xl:ml-32'>
            <ImagePoster src={Image6} alt='image6' style='h-96 3xl:h-[410px] hidden lg:block' margin='ml-20 3xl:ml-24 mb-10' />
            <ImagePoster src={Image5} alt='image5' style='h-60 3xl:h-64 hidden lg:block' />
          </div>
        </div>
      </div>
    </div>
  )
}

const ImagePoster = ({ src, style, margin }) => {
  return (
    <div className={`p-2 border-ja-white border ${margin}`}>
      <img src={src} alt='' className={`${style}`} />
    </div>
  )
}

export default ImageCarousel

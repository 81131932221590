import React, { useState } from 'react'

import BackgroundPhoto from './assets/img/registry/registry.webp'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Logo from './assets/img/aj-logo.svg'
import { FaGamepad } from 'react-icons/fa'

//Mobile standard font - 2xl
//Large standard font - 3xl

const ClassicButton = ({ className = '' }) => {
  // State to manage hover status
  const [isHovered, setIsHovered] = useState(false)
  const navigate = useNavigate()

  // Animation variants for the arrow
  const arrowVariants = {
    initial: {
      x: -60, // Start the arrow slightly to the left of its final visible position to stay hidden
      opacity: 0, // Start fully transparent
    },
    hover: {
      x: -28, // Move the arrow right next to the RSVP text but within a safe distance
      opacity: 1, // Make the arrow fully visible
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
  }

  // Animation variants for the RSVP text
  const textVariants = {
    initial: {
      x: 0, // Centered text
    },
    hover: {
      x: 14, // Move text slightly to the right to make room for the arrow, adjust this value if needed
      transition: { duration: 0.5, ease: 'easeInOut' },
    },
  }

  return (
    <div>
      <motion.button
        className={`w-40 h-16 text-sm border bg-opacity-90 py-2 px-6 flex items-center justify-center relative overflow-hidden rounded-lg ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => navigate('/classic')}
      >
        {/* Arrow animated from left to right on hover, positioned to the left of the text */}
        <motion.span
          className='absolute' // This setting positions the arrow slightly to the left of the text center
          variants={arrowVariants}
          animate={isHovered ? 'hover' : 'initial'}
        >
          →
        </motion.span>
        {/* RSVP text that shifts right on hover to avoid overlapping */}
        <motion.span className='mx-auto text-3xl' variants={textVariants} animate={isHovered ? 'hover' : 'initial'}>
          Enter
        </motion.span>
      </motion.button>
    </div>
  )
}

function EntryPortal() {
  return (
    <div
      className={`flex flex-col items-center text-2xl font-nesans text-white bg-gradient-to-br from-slate-900 to-slate-400`}
      style={{
        backgroundImage: `url(${BackgroundPhoto})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='container flex flex-col items-center justify-center w-full pt-48'>
        <a href='/' className={`z-10 absolute top-10 left-10`}>
          <img src={Logo} alt='logo' className='h-24 md:h-32 inline-block mb-10 xl:mb-20' />
        </a>
        <div className='flex flex-col items-center p-4 text-5xl mb-8'>Jocelyn & Andrew</div>
        <p className='mb-8 xl:mb-4 xl:flex'>Welcome to our wedding website!</p>
        <p className='mb-8 hidden xl:flex'>Please select your experience</p>
        <div className='flex flex-row w-full items-center justify-center space-x-24'>
          <ClassicButton className='bg-ja-white text-ja-gray' />
          <Link className='hidden xl:flex' to='gamer'>
            <div className='flex w-40 h-16 items-center justify-center text-center text-3xl px-4 py-2 font-avilock bg-red-400 group hover:bg-white hover:text-black'>
              <p className='mr-2'>Enter</p>
              <FaGamepad className='text-white text-4xl mb-1 group-hover:text-black' />
            </div>
          </Link>
        </div>
      </div>
      <p className='mt-12 text-xl xl:flex px-14 text-center'>
        (For optimal experience, we suggest viewing on a Laptop or PC.)
      </p>
    </div>
  )
}

export default EntryPortal

import React, { useState } from 'react'
import Logo from '../../assets/img/aj-logo.svg'
import { FiMenu, FiX } from 'react-icons/fi'
import { motion } from 'framer-motion'

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const variants = {
    open: { opacity: 1, height: 256, z: 50 },
    closed: { opacity: 1, height: 80, z: 10 },
  }

  const logoVariants = {
    showing: { opacity: 1, height: 56 },
    hidden: { opacity: 0, height: 56 },
  }

  const menuItemVariants = {
    closed: {
      opacity: 0,
      y: -20, // Adjust as needed for the effect
    },
    open: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1 + 0.2, // Each item starts 0.1s after the previous, adjust as needed
        duration: 0.2, // Duration of each item's animation
      },
    }),
  }

  const scrollOnClick = (hash) => {
    setIsMenuOpen(false)
    window.location.hash = hash
    // Smoothly scroll to the section with ID 'about'
    const section = document.getElementById(hash)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const linkClassName = 'text-ja-gray text-lg lg:text-2xl text-left hover:text-gray-400 my-1 mx-1 px-1 lg:ml-4'
  const mobileMenuClassName =
    'absolute flex flex-col pt-8 items-start w-full lg:hidden'//backdrop-blur-sm
  const pcMenuClassName = 'hidden lg:flex items-center justify-end'

  return (
    <header
      className={`text-ja-gray flex flex-col items-center top-0 z-50 w-full bg-opacity-90 absolute`}
    >
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl  flex flex-start z-50'>
        <nav className='relative flex flex-row  w-full justify-between min-h-20 '>
          <div className='flex flex-row items-center w-full lg:w-1/3 justify-between px-6 py-6'>
            <motion.div
              initial='showing'
              animate={isMenuOpen ? 'hidden' : 'showing'}
              variants={logoVariants}
              transition={{ duration: 0.5, ease: 'easeInOut' }}
              className='h-14 z-20'
            >
              <a href='/' className={`z-10 ${isMenuOpen ? 'invisible' : 'visible'}`}>
                <img src={Logo} alt='logo' className='h-12 md:h-16 inline-block' />
              </a>
            </motion.div>
            <button className={`absolute right-6 flex z-50 lg:hidden`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <FiX className={`text-4xl`} /> : <FiMenu className='text-4xl' />}
            </button>
          </div>
          {/* Navigation Links */}

          {/* Mobile Menu */}
          <motion.div
            initial='closed'
            animate={isMenuOpen ? 'open' : 'closed'}
            variants={variants}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className={`${mobileMenuClassName} ${isMenuOpen ? 'z-20' : 'z-0'}`}
          >
            <div
              id='menu'
              className={`text-lg flex flex-col z-50 px-10 ${
                isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0 lg:max-h-96 lg:opacity-100'
              }`}
            >
              <motion.button
                key={'our-story-header'}
                custom={0} // This is used for calculating delay
                variants={menuItemVariants} // Your defined variants
                initial='closed'
                animate={isMenuOpen ? 'open' : 'closed'}
                onClick={() => {
                  scrollOnClick('our-story')
                }}
                className={linkClassName}
              >
                Our Story
              </motion.button>
              <motion.button
                key={'schedule-header'}
                custom={1} // This is used for calculating delay
                variants={menuItemVariants} // Your defined variants
                initial='closed'
                animate={isMenuOpen ? 'open' : 'closed'}
                onClick={() => {
                  scrollOnClick('schedule')
                }}
                className={linkClassName}
              >
                Schedule
              </motion.button>
              <motion.button
                key={'travel-header'}
                custom={2} // This is used for calculating delay
                variants={menuItemVariants} // Your defined variants
                initial='closed'
                animate={isMenuOpen ? 'open' : 'closed'}
                onClick={() => {
                  scrollOnClick('travel-info')
                }}
                className={linkClassName}
              >
                Travel & Stay
              </motion.button>
              <motion.button
                key={'registry-header'}
                custom={3} // This is used for calculating delay
                variants={menuItemVariants} // Your defined variants
                initial='closed'
                animate={isMenuOpen ? 'open' : 'closed'}
                onClick={() => {
                  scrollOnClick('registry')
                }}
                className={linkClassName}
              >
                Registry
              </motion.button>
              <motion.button
                key={'faq-header'}
                custom={4} // This is used for calculating delay
                variants={menuItemVariants} // Your defined variants
                initial='closed'
                animate={isMenuOpen ? 'open' : 'closed'}
                onClick={() => {
                  scrollOnClick('faq')
                }}
                className={linkClassName}
              >
                FAQ
              </motion.button>
            </div>
          </motion.div>

          {/* Laptop/Desktop Menu */}
          <div className={pcMenuClassName}>
            <div
              id='menu'
              className={`text-xl flex flex-row z-50 max-h-0 opacity-0 lg:max-h-96 lg:opacity-100
              `}
            >
              <a href='#our-story' className={linkClassName}>
                Our Story
              </a>
              <a href='#schedule' className={linkClassName}>
                Schedule
              </a>
              <a href='#travel-info' className={linkClassName}>
                Travel & Stay
              </a>
              <a href='#registry' className={linkClassName}>
                Registry
              </a>
              <a href='#faq' className={linkClassName}>
                FAQ
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header

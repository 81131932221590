import React from 'react'
import Logo from '../../assets/img/aj-logo.svg'
import { HBSHeader, MundayHeader } from './constants/fontConstants'
import { FAQ_ITEMS } from '../../common/Strings'

const Footer = () => {
  return (
    <div
      id='faq'
      className='flex scroll-mt-0 bg-ja-white text-ja-gray w-full items-stretch justify-center py-10 lg:py-16 lg:px-10'
    >
      <div className='container flex flex-col items-center justify-center p-10 border-t-2 border-gray-300 mx-2'>
        <img src={Logo} alt='logo' className='h-16 md:h-24 inline-block' />
        <p className='mt-6 text-center'>Thanks for visiting our website! </p>
        <p className='mt-6 text-center'>We can't wait to celebrate our special day with you!</p>
      </div>
    </div>
  )
}

export default Footer

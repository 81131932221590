import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import EntryPortal from './EntryPortal'
import Classic from './classic/index'
import Game from './game/index'
import Home from './game/components/Home'
import OurStory from './game/components/OurStory'
import TravelStay from './game/components/TravelStay'
import Registry from './game/components/Registry'
import Schedule from './game/components/Schedule'
import FAQ from './game/components/FAQ'
import ErrorPage from './common/components/ErrorPage'
import reportWebVitals from './reportWebVitals'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <EntryPortal />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'classic',
    element: <Classic />,
    errorElement: <ErrorPage />,
  },
  {
    path: 'gamer',
    element: <Game />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: 'our-story',
        element: <OurStory />,
      },
      {
        path: 'travel',
        element: <TravelStay />,
      },
      {
        path: 'registry',
        element: <Registry />,
      },
      {
        path: 'schedule',
        element: <Schedule />,
      },
      {
        path: 'faq',
        element: <FAQ />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

reportWebVitals()
import React from 'react'
import TravelBackground from '../../assets/img/travel/travel_background.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'

import {
  EBELL_ADDRESS_TITLE,
  EBELL_STREET_ADDRESS,
  EBELL_CITY_ZIP,
  EBELL_GOOGLE_URL,
  PARKING_ADDRESS_TITLE,
  PARKING_CITY_ZIP,
  PARKING_STREET_ADDRESS,
  PARKING_GOOGLE_URL,
  TRAFFIC_INFO,
  PARKING_INFO
} from '../../common/Strings'

const TravelInfo = () => {
  const textClass = 'text-2xl lg:text-3xl font-light'
  const addressTextClass = 'text-2xl lg:text-3xl font-light'
  const linkClass = 'text-ja-white underline text-2xl lg:text-3xl hover:text-blue-300 font-light'
  const addressHeaderText = 'text-4xl lg:text-5xl mb-2 lg:mb-4'

  return (
    <div
      id='travel-info'
      className='scroll-mt-0 text-ja-white flex flex-col items-center w-full min-h-[1024px]'
      style={{
        backgroundImage: `url(${TravelBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl flex flex-col py-10 lg:py-16 items-center justify-center '>
        {/* Main Headline */}
        <div className='flex w-full items-center justify-center px-8'>
          <div className='flex flex-row items-center justify-start'>
            <h1 className={MundayHeader}>TR</h1>
            <h1 className={HBSHeader}>A</h1>
            <h1 className={MundayHeader}>VEL</h1>
          </div>
        </div>
        <div className='flex flex-col items-center justify-center w-full mt-6'>
          <div className='flex flex-col w-full lg:w-2/3 px-8 3xl:px-28 items-center justify-center'>
            <div className='flex flex-col text-center'>
              <p className={`${textClass} mb-3 mt-10 lg:mt-0`}>{TRAFFIC_INFO}</p>
              <p className={`${textClass} mb-4 lg:mb-0`}>
                {PARKING_INFO}
              </p>
            </div>
          </div>
          <div className='flex flex-col w-full justify-center items-center mt-20'>
            <div className='flex flex-col lg:flex-row w-full lg:mt-10'>
              <div className='text-center w-full flex flex-col items-start lg:items-center mb-10 px-10'>
                {/* <div className='flex flex-row items-center justify-start lg:justify-center h-20'> */}
                {/* <div className='flex-grow h-[2px] bg-ja-gray flex' /> */}
                <h2 className={addressHeaderText}>VENUE</h2>
                {/* <div className='flex-grow h-[2px] bg-ja-gray hidden md:flex' /> */}
                {/* </div> */}
                <div className='flex flex-col items-start lg:items-center'>
                  <p className={addressTextClass}>{EBELL_ADDRESS_TITLE}</p>
                  <p className={addressTextClass}>{EBELL_STREET_ADDRESS}</p>
                  <p className={addressTextClass}>{EBELL_CITY_ZIP}</p>
                  <a
                    href={EBELL_GOOGLE_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={linkClass}
                  >
                    View on Google Maps
                  </a>
                </div>
              </div>
              <div className='text-center w-full flex flex-col items-end lg:items-center mb-10 px-10'>
                {/* <div className='flex flex-row items-center justify-center h-20'> */}
                {/* <div className='flex-grow h-[2px] bg-ja-gray hidden md:flex' /> */}
                <h2 className={addressHeaderText}>PARKING</h2>
                {/* <div className='flex-grow h-[2px] bg-ja-gray flex' /> */}
                {/* </div> */}
                <div className='flex flex-col items-end lg:items-center'>
                  <p className={addressTextClass}>{PARKING_ADDRESS_TITLE}</p>
                  <p className={addressTextClass}>{PARKING_STREET_ADDRESS}</p>
                  <p className={addressTextClass}>{PARKING_CITY_ZIP}</p>
                  <a
                    href={PARKING_GOOGLE_URL}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={linkClass}
                  >
                    View on Google Maps
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TravelInfo

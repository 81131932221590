import React from 'react'
import HeroImage from '../../assets/img/hero/HeroImage2.webp'
import RSVPButton from './RSVPButton'
import { HBSAmpersand, HBSHeader, MundayHeader } from './constants/fontConstants'

const HeroSection = () => {
  return (
    <div className='text-ja-gray flex flex-col items-center md:flex-row md:justify-between w-full'>
      <div className='flex w-full h-[1024px] md:h-[1240px] pt-60 items-start justify-center relative'>
        <div
          className='absolute h-[1024px] md:h-full top-0 left-0 w-full bg-center bg-cover'
          style={{
            backgroundImage: `url(${HeroImage})`,
            backgroundPosition: 'center',
          }}
        />
        <div className='flex w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-2xl  items-center justify-start z-40'>
          <div className='flex flex-col w-full lg:w-3/5 items-center justify-center'>
            <div className='flex flex-col w-full items-center justify-center z-10'>
              <div className='flex flex-row items-center justify-start w-full px-10 md:px-14 lg:px-20'>
                <h1 className={`font-hbs ${HBSHeader} leading-[80px] md:leading-[96px]`}>J</h1>
                <h1 className={`font-munday ${MundayHeader} ml-1 lg:ml-3 leading-[80px] md:leading-[96px]`}>OCELYN</h1>
              </div>
              <div className='flex flex-row items-center my-0 lg:my-2 w-full px-6 md:px-20 lg:px-28 '>
                <h1 className={`font-hbs ${HBSAmpersand} mx-1 lg:mx-4 ml-auto leading-[80px] md:leading-[96px]`}>{`&`}</h1>
                <h1 className={`font-hbs ${HBSHeader} ml-1 md:ml-6 leading-[80px] md:leading-[96px]`}>A</h1>
                <h1 className={`font-munday ${MundayHeader}  ml-1 lg:ml-3 leading-[80px] md:leading-[96px]`}>NDREW</h1>
              </div>
              <div className='flex my-10 w-full items-center justify-center'>
                <RSVPButton className='bg-ja-white text-ja-gray' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection

import React from 'react'
import TravelStayBackground from '../assets/backgrounds/travel-stay-background.webp'
import Photo from '../assets/photos/ebell-floor-plan.webp'
import {
  EBELL_ADDRESS_TITLE,
  EBELL_STREET_ADDRESS,
  EBELL_CITY_ZIP,
  EBELL_GOOGLE_URL,
  PARKING_ADDRESS_TITLE,
  PARKING_CITY_ZIP,
  PARKING_STREET_ADDRESS,
  PARKING_GOOGLE_URL,
  TRAFFIC_INFO,
  PARKING_INFO,
  WESTIN_ADDRESS_TITLE,
  WESTIN_STREET_ADDRESS,
  WESTIN_CITY_ZIP,
  WESTIN_GOOGLE_URL,
  HYATT_ADDRESS_TITLE,
  HYATT_STREET_ADDRESS,
  HYATT_CITY_ZIP,
  HYATT_GOOGLE_URL,
} from '../../common/Strings'
import WestinPhoto from '../../assets/img/stay/westin.webp'
import HyattPhoto from '../../assets/img/stay/hyatt.webp'
const addressInfoClass = 'text-base 3xl:text-xl 4xl:text-2xl'

function TravelStay() {
  return (
    <div
      className='flex flex-row items-center justify-center text-2xl overflow-hidden pt-[120px]'
      style={{
        backgroundImage: `url(${TravelStayBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='w-[1200px] 3xl:w-[1600px] 4xl:w-[1800px] items-center justify-center h-full flex flex-col px-10 3xl:px-20'>
        <p className='text-game-header-yellow text-8xl 3xl:text-9xl 4xl:text-10xl'>TRAVEL & STAY</p>
        <div className='flex flex-row w-full h-full items-center justify-center'>
          <div className='flex flex-col w-1/3 h-full items-center px-5 text-center pt-12'>
            <div className='flex flex-col text-white font-sans text-xl lg:text-2xl'>
              <p className='font-avilock text-3xl 3xl:text-4xl 4xl:text-5xl mb-4'>Venue</p>
              <p className={addressInfoClass}>{EBELL_ADDRESS_TITLE}</p>
              <p className={addressInfoClass}>{EBELL_STREET_ADDRESS}</p>
              <p className={addressInfoClass}>{EBELL_CITY_ZIP}</p>
              <a
                href={EBELL_GOOGLE_URL}
                target='_blank'
                rel='noopener noreferrer'
                className='text-ja-white underline text-base 3xl:text-xl 4xl:text-2xl hover:text-blue-300 font-light'
              >
                View On Google Maps
              </a>
            </div>
            <div className='flex flex-col text-white mt-24 3xl:mt-[100px] 4xl:mt-44 font-sans text-xl lg:text-2xl'>
              <p className='text-3xl 3xl:text-4xl 4xl:text-5xl mb-4 font-avilock'>Parking</p>
              <p className={addressInfoClass}>{PARKING_ADDRESS_TITLE}</p>
              <p className={addressInfoClass}>{PARKING_STREET_ADDRESS}</p>
              <p className={addressInfoClass}>{PARKING_CITY_ZIP}</p>
              <a
                href={PARKING_GOOGLE_URL}
                target='_blank'
                rel='noopener noreferrer'
                className='text-ja-white underline text-base 3xl:text-xl 4xl:text-2xl hover:text-blue-300 font-light'
              >
                View On Google Maps
              </a>
            </div>
          </div>

          <div className='flex flex-col w-1/3 px-5 h-full items-center pt-12'>
            <p className='text-lg 3xl:text-xl 4xl:text-2xl mb-14 3xl:mb-16 4xl:mb-20 text-white text-center font-sans'>{TRAFFIC_INFO}</p>
            <img src={Photo} alt='' className='flex w-5/6' />
            <p className='mt-14 3xl:mt-16 4xl:mt-20 text-lg 3xl-text-xl 4xl:text-2xl text-white text-center font-sans'>{PARKING_INFO}</p>
          </div>
          <div className='flex flex-col w-1/3 h-full items-center pt-12 px-5'>
            <p className='text-white text-3xl 3xl:text-4xl 4xl:text-5xl'>Lodging Recommendations</p>
            <HotelInfo
              title={WESTIN_ADDRESS_TITLE}
              address={WESTIN_STREET_ADDRESS}
              cityZip={WESTIN_CITY_ZIP}
              googleURL={WESTIN_GOOGLE_URL}
              img={WestinPhoto}
            />
            <HotelInfo
              title={HYATT_ADDRESS_TITLE}
              address={HYATT_STREET_ADDRESS}
              cityZip={HYATT_CITY_ZIP}
              googleURL={HYATT_GOOGLE_URL}
              img={HyattPhoto}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function HotelInfo({ title, address, cityZip, googleURL, img }) {
  return (
    <div className='text-white text-xl 3xl:text-2xl flex flex-row w-full justify-between px-2 mt-20 4xl:mt-32 font-sans'>
      <div className='flex flex-col'>
        <p className={addressInfoClass}>{title}</p>
        <p className={addressInfoClass}>{address}</p>
        <p className={addressInfoClass}>{cityZip}</p>
        <a
          href={googleURL}
          target='_blank'
          rel='noopener noreferrer'
          className='text-ja-white underline text-base 3xl:text-xl 4xl:text-2xl hover:text-blue-300 font-light'
        >
          View On Google Maps
        </a>
      </div>
      <div
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        className='w-[140px] h-[90px] 3xl:w-[210px] 3xl:h-[120px]'
      />
    </div>
  )
}

export default TravelStay

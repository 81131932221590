import React from 'react'
import HomeBackground from '../assets/backgrounds/home-background.webp'
import { Link } from 'react-router-dom'

const openUrl = () => {
  window.open('https://www.zola.com/wedding/andrewlyn/rsvp', '_blank')
}

function Home() {
  return (
    <div
      className='flex flex-col items-start justify-center'
      style={{
        backgroundImage: `url(${HomeBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh', // Ensure it covers the full viewport height
        width: '100%', // Ensure it covers the full width
      }}
    >
      <div className='flex flex-col items-start justify-center ml-32'>
        <RSVPButton />
        <Button text='Our Story' path='/gamer/our-story' />
        <Button text='Schedule' path='/gamer/schedule' />
        <Button text='Travel & Stay' path='/gamer/travel' />
        <Button text='Registry' path='/gamer/registry' />
        <Button text='FAQ' path='/gamer/faq' />
      </div>
    </div>
  )
}

function Button({ text, path }) {
  return (
    <div className='flex flex-row items-center justify-start w-[440px] h-24 group'>
      <div className='h-3 w-3 bg-white rotate-45 transition-transform duration-300 group-hover:rotate-[135deg] group-hover:bg-game-teal' />
      <Link to={path}>
        <div className='text-white text-6xl hover:text-7xl font-bold py-2 px-4 rounded drop-shadow-2xl group-hover:text-game-teal'>
          {text}
        </div>
      </Link>
    </div>
  )
}

function RSVPButton() {
  return (
    <div className='flex flex-row items-center justify-start w-96 h-24 group'>
      <div className='h-3 w-3 bg-red-400 rotate-45 transition-transform duration-300 group-hover:rotate-[135deg] group-hover:bg-game-teal' />
      <div
        onClick={openUrl}
        className='text-red-400 text-8xl group-hover:text-9xl py-2 px-4 rounded group-hover:text-game-teal cursor-pointer'
      >
        RSVP
      </div>
    </div>
  )
}

export default Home

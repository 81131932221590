import React from 'react'
import RSVPButton from './RSVPButton'
import Ebell from '../../assets/img/schedule/ebell.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'

const Schedule = () => {
  return (
    <div
      id='schedule'
      className='scroll-mt-0 text-ja-gray flex items-center justify-center w-full'
    >
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl flex flex-col lg:flex-row py-10 lg:py-16 px-10 lg:px-20 lg:items-center'>
        {/* Main Headline */}
        <div className='flex flex-col items-center justify-center w-full lg:w-1/2 text-left md:mb-0'>
          <div className='flex flex-row items-center justify-center w-full '>
            <h1 className={MundayHeader}>SCHE</h1>
            <h1 className={HBSHeader}>D</h1>
            <h1 className={MundayHeader}>ULE</h1>
          </div>
          <div className='flex flex-col w-full items-center md:items-start justify-center md:justify-start'>
            <div className='flex flex-col w-full items-center justify-center md:items-start my-10'>
              <p className='text-4xl lg:text-5xl mb-1'>The Ebell Club Long Beach</p>
              <p className='text-xl lg:text-2xl'>290 Cerritos Ave, Long Beach, CA 90802</p>
            </div>
            <ScheduleItem
              header='Guest Arrival'
              time='5:00PM'
            />
            <ScheduleItem
              header='Wedding Ceremony'
              time='5:30PM'
            />
            <ScheduleItem
              header='Cocktail Hour'
              time='6:00PM'
            />
            <ScheduleItem
              header='Dinner & Reception'
              time='7:00PM'
            />
            <div className='mt-10 w-full flex items-center md:justify-start justify-center'>
              <RSVPButton className='border-ja-gray text-ja-gray' />
            </div>
          </div>
        </div>
        <div className='flex flex-shrink border-2 w-full h-1/2 lg:w-1/3 ml-auto border-gray-300 p-2 mt-16'>
          <div
            style={{
              backgroundImage: `url(${Ebell})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className='w-full h-[460px]'
          />
        </div>
      </div>
    </div>
  )
}

const ScheduleItem = ({ header, time }) => {
  return (
    <div className='flex flex-row items-center justify-between w-full mb-8'>
      <p className='text-3xl lg:text-4xl font-bold mr-2 lg:mr-4'>{time}</p>
      <div className='flex-grow h-[2px] bg-ja-gray' />
      <h1 className='text-2xl lg:text-3xl font-light mb-1 lg:mb-2 ml-2 lg:ml-4 text-right'>{header}</h1>
    </div>
  )
}

export default Schedule

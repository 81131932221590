import { useState } from 'react';
import { motion } from 'framer-motion';


const openUrl = () => {
  window.open('https://www.zola.com/wedding/andrewlyn/rsvp', '_blank');
};

const RSVPButton = ({className = ''}) => {
  // State to manage hover status
  const [isHovered, setIsHovered] = useState(false);

  // Animation variants for the arrow
  const arrowVariants = {
    initial: {
      x: -60,  // Start the arrow slightly to the left of its final visible position to stay hidden
      opacity: 0  // Start fully transparent
    },
    hover: {
      x: -28,  // Move the arrow right next to the RSVP text but within a safe distance
      opacity: 1,  // Make the arrow fully visible
      transition: { duration: 0.5, ease: 'easeInOut' }
    }
  };

  // Animation variants for the RSVP text
  const textVariants = {
    initial: {
      x: 0  // Centered text
    },
    hover: {
      x: 14,  // Move text slightly to the right to make room for the arrow, adjust this value if needed
      transition: { duration: 0.5, ease: 'easeInOut' }
    }
  };

  return (
    <div>
      <motion.button
        className={`w-72 text-sm border bg-opacity-90 py-2 px-6 flex items-center justify-center relative overflow-hidden rounded-lg ${className}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={openUrl}
      >
        {/* Arrow animated from left to right on hover, positioned to the left of the text */}
        <motion.span
          className="absolute" // This setting positions the arrow slightly to the left of the text center
          variants={arrowVariants}
          animate={isHovered ? 'hover' : 'initial'}
        >
          →
        </motion.span>
        {/* RSVP text that shifts right on hover to avoid overlapping */}
        <motion.span
          className="mx-auto text-3xl"
          variants={textVariants}
          animate={isHovered ? 'hover' : 'initial'}
        >
          RSVP
        </motion.span>
      </motion.button>
    </div>
  );
}

export default RSVPButton;

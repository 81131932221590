import React from 'react'
import Image from '../../assets/img/faq/faq.webp'
import { HBSHeader, MundayHeader } from './constants/fontConstants'
import { FAQ_ITEMS } from '../../common/Strings'

const FAQItem = ({ question, answer, link }) => {
  const questionClass = 'text-3xl font-bold mb-2 uppercase'
  const answerClass = 'text-2xl font-light'

  return (
    <div className='mb-8'>
      <h3 className={questionClass}>{question}</h3>
      <p className={answerClass}>
        {answer}
        {link && (
          <a href={link} target='_blank' className='text-blue-400 hover:text-blue-300 '>
            &nbsp;black-tie optional.
          </a>
        )}
      </p>
    </div>
  )
}

const FAQ = () => {
  return (
    <div
      id='faq'
      className='flex scroll-mt-0 bg-ja-white text-ja-gray w-full items-stretch justify-center py-10 lg:py-16 lg:px-10'
    >
      <div className='w-full max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl 3xl:max-w-screen-3xl  flex flex-col lg:flex-row items-start px-10'>
        <div className='flex flex-col w-full h-full lg:w-1/3'>
          <div className='flex flex-row items-center justify-center w-full px-10'>
            <h1 className={HBSHeader}>F</h1>
            <h1 className={MundayHeader}>AQ</h1>
          </div>
          <div className='hidden lg:flex border-2 border-gray-300 p-2 mt-auto w-full'>
            <div
              style={{
                backgroundImage: `url(${Image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
              className='w-full h-[400px] xl:h-[500px]'
            />
          </div>
        </div>
        <div className='flex flex-row w-full lg:w-2/3 mt-10 lg:mt-0 lg:px-5'>
          <div className='flex flex-col lg:ml-10'>
            <div className='overflow-auto'>
              {FAQ_ITEMS.map((item, index) => (
                <FAQItem key={index} question={item.question} answer={item.answer} link={item.link} />
              ))}
            </div>
          </div>
        </div>
        <div className='lg:hidden border-2 border-gray-300 p-2 mt-10 w-full'>
          <div
            style={{
              backgroundImage: `url(${Image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            className='w-full h-[400px]'
          />
        </div>
      </div>
    </div>
  )
}

export default FAQ

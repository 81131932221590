import React from 'react'
import Header from './components/Header'
import HeroSection from './components/HeroSection'
import ImageCarousel from './components/ImageCarousel'
import Schedule from './components/Schedule'
import Travel from './components/Travel'
import Stay from './components/Stay'
import Registry from './components/Registry'
import FAQ from './components/FAQ'
import OurStory from './components/OurStory'
import Footer from './components/Footer'
import BackToTop from './components/BackToTop'

//Mobile standard font - 2xl
//Large standard font - 3xl

function Classic() {
  return (
    <div className={`flex flex-col bg-stone-100 items-center text-2xl overflow-hidden font-nesans`}>
      <Header />
      <HeroSection />
      <OurStory />
      <ImageCarousel />
      <Schedule />
      <Travel />
      <Stay />
      <Registry />
      <FAQ />
      <Footer />
      <BackToTop />
    </div>
  )
}

export default Classic
